import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Tab from "./tab.js";
import { Section } from "./Theme";

const ProductGrid = styled.div`
    // grid-column: 2/12;
    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: repeat(3, [col] auto );
    // grid-template-rows: repeat(3, [row] auto );

    display: flex;
    flex-wrap: wrap;

    ${media.lessThan("medium")`
        display: block;
        grid-column: 1/13;
    `}

    h2 {
         margin-bottom: 60px;
    }
`;

const ProductOuterWrapper = styled(Section)`
    display: block !important;
    max-width: 1280px;

    ${media.lessThan("large")`
        max-width: 100%;
    `}
`

const TabsSection = styled(Section)`
    ${media.lessThan("large")`    
        display: block;
        max-width: 90%;
        padding-top: 0;
        padding-bottom: 20px;
    `}
`;

const TabbedHeader = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    grid-column: 1/13;
    text-align: center;

    li {
        display: inline-block;
        margin: 0 15px;
        padding: 0;
        list-style-type: none;
        opacity: 0.4;
        font-size: 16px;
        cursor: pointer;

        &:hover,
        &.tab-list-active {
            opacity: 1;
        }

        &.tab-list-active span,
        &:hover span {
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }

        ${media.lessThan("large")`
            display: block;
            margin: 10px 5px;
        `}
    }
`;

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label
        };
    }

    onClickTabItem = tab => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab }
        } = this;

        return (
          <>
            <TabsSection small className="tabs">
              <TabbedHeader className="tab-list">
                {children.map(child => {
                  const { label } = child.props;

                  return (
                    <Tab
                      activeTab={activeTab}
                      key={label}
                      label={label}
                      onClick={onClickTabItem}
                    />
                  );
                })}
                {/* <li className="tab-list-item">
                    <Link to="/custom-order" className="caption">Custom Prints</Link>
                </li> */}
              </TabbedHeader>
            </TabsSection>

            <ProductOuterWrapper className="tab-content">
              <ProductGrid>
                {children.map(child => {
                  if (child.props.label !== activeTab) return undefined;
                  return child.props.children;
                })}
              </ProductGrid>
            </ProductOuterWrapper>
          </>
        );
    }
}

export default Tabs;
