import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Footer from "../../components/footer.js";
import Header from "../../components/Header";
import Layout from "../../components/layout";
import Tabs from "../../components/tabs.js";
import Text, { Section } from "../../components/Theme";
import IntroSectionBackground from "../../images/background__printshop2.jpeg";

// const PrintshopHeader = styled.div`
//     ${Grid};
//     min-height: 100vh;
// `
// const ProductRowHeader = styled(Text)`
//     margin-bottom: 60px;
//     font-size: 16px;
// `;
const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);

    Section {
        min-height: calc(60vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;
    }

    .intro {
        &__category {
            text-transform: uppercase;
            border: 1px solid var(--white);
            padding: 4px 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
`;

const Filters = styled(Tabs)`
  ul {
    text-align: center !important;
  }
`;

const SearchWrapper = styled(Section)`
  display: block;
  padding: 80px 80px 0;
  text-align: center;
  max-width: 1280px;

  ${media.lessThan("large")`
      max-width: 100%;
  `}  

  input {
    width: 100%;

    ${media.lessThan("large")`
      margin-bottom: 2rem;
    `}
  }

  ${media.lessThan("large")`
    padding: 40px 25px 0;
  `}
`;

const ProductImage = styled.div`
  position: relative;
  overflow: hidden;
  ${media.greaterThan("large")`
        .hover-img {
            transition: all 200ms ease-in-out;
        }
        &:hover .hover-img {
            opacity: 1 !important;
        }
    `}
`;

const DailyFlex = styled.div`
    padding: 0 30px;
    width: 33.3%;

    ${media.lessThan("medium")`
      width: 100%;
    `}

    .gatsby-image-wrapper {
        height: 33vh;

        div {
            padding-bottom: 127.85%;
        }
    }

    ${media.lessThan("medium")`
        padding: 30px 15px;
    `}

    ${media.greaterThan("medium")`
        &:nth-of-type(10n+1) { width: 66.6%; grid-column: col / span 2; }
        &:nth-of-type(10n+2) { grid-column: col 3 / span 1; }
        &:nth-of-type(10n+3) { grid-column: col 1 / span 1; }
        &:nth-of-type(10n+4) { width: 66.6%; grid-column: col 2 / span 2; }
    `}
`

const OverviewContent = styled.div`
    width: 100%;
    padding: 10px 0 25px 0;
    margin-bottom: 60px;

    ${media.lessThan("medium")`
        margin-bottom: 0;
    `}

    ${media.greaterThan("medium")`
        grid-column-start: 8;
    `}

    .overview-geo {
        margin-bottom: 30px;
        opacity: 0.7;

        ${media.lessThan("medium")`
            margin-bottom: 15px;
        `}
    }

    .overview-source {
        opacity: 0.7;
    }

    .overview-description {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 40px;

        ${media.lessThan("medium")`
            margin-bottom: 20px;
        `}
    }

    .meta-btns {
      display: none;
        ${media.lessThan("large")`
            // display: block;
            display: none;

            .share-container {
                margin-top: 20px;
            }
        `}
    }

    .daily-headers {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: center;

        ${media.lessThan("medium")`
            font-size: 21px;
        `}        
    }
`;

const OverviewImg = styled.div`
    max-width: 100%;
    display: block;
    width: 100%;

    ${media.greaterThan("medium")`
        align-items: center;
        grid-column: 2/8;
        padding-right: 0;
        padding-left: 0;
    `}
`

// const PageHeader = styled.h1`
//   grid-column: 2 /11;
//   padding: 60px 0 0 0;
//   font-weight: 100;
//   font-size: 52px;
//   line-height: 79px;
//   ${media.lessThan("large")`
//         padding-bottom: 20px;
//         font-size: 36px;
//         line-height: 48px;
//     `}
// `;

const ArticleLink = styled.span`
    margin-top: 10px;
    display: block;

    a {
        text-decoration: underline;
    }
`;

const ProductWrapper = styled.div`
  grid-column: span 1;
  // margin-bottom: 120px;
`;

const Input = styled.input`
  grid-column: 2 /11;
  width: 280px;
  padding: 10px;
  border: 0px;
  border-radius: 2px;
  background-color: #f7f7f7;
`;

const PrintsPage = ({ data }) => {
  const [query, setQuery] = useState("");

  const facebookUrl =
              "https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/shop/";

  const filterProducts = useMemo(() => {
    if (!query || query.length <= 2) return () => true;
    return ({ node }) => node.title.toLowerCase().includes(query.toLowerCase());
  }, [query]);

  const allProductNodes = useMemo(() => {
    return data.allDatoCmsProduct.edges
      .filter(filterProducts)
      .map(({ node: product }) => (
        <DailyFlex
          key={product.id}
          id={product.id}
          className=""
          key={product.id}
        >
          <ProductWrapper>
              <OverviewImg
                  gutter={product.productimg[0].fluid.aspectRatio}
              >
                <Link
                  to={`/shop/${product.slug}`}
                >
                  <Img
                      fluid={product.productimg[0].fluid}
                      alt={product.title}
                      style={{ backgroundColor: "black" }}
                      durationFadeIn={500}
                      placeholderStyle={
                          {
                              // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                              // "boxShadow": "0 0 100px 200px inset black",
                          }
                      }
                  />
                </Link>
              </OverviewImg>
              <OverviewContent>
                  <h3
                      id="w-node-0e4a4f7ac915-5940d611"
                      className="subheader daily-headers"
                  ><Link to={`/shop/${product.slug}`}>
                      {product.title}
                    </Link>
                  </h3>
              </OverviewContent>
          </ProductWrapper>
        </DailyFlex>
      ))
  }, [data, filterProducts]);

  const newReleaseNodes = useMemo(
    () =>
      data.newreleases.edges
        .filter(filterProducts)
        .map(({ node: product }) => (
        <DailyFlex
          key={product.id}
          id={product.id}
          className="cell small-12 medium-6"
          key={product.id}
        >
          <ProductWrapper>
              <OverviewImg
                  gutter={product.productimg[0].fluid.aspectRatio}
              >
                <Link
                  to={`/shop/${product.slug}`}
                >
                  <Img
                      fluid={product.productimg[0].fluid}
                      alt={product.title}
                      style={{ backgroundColor: "black" }}
                      durationFadeIn={500}
                      placeholderStyle={
                          {
                              // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                              // "boxShadow": "0 0 100px 200px inset black",
                          }
                      }
                  />
                </Link>
              </OverviewImg>
              <OverviewContent>
                  <h3
                      id="w-node-0e4a4f7ac915-5940d611"
                      className="subheader daily-headers"
                  ><Link to={`/shop/${product.slug}`}>
                      {product.title}
                    </Link>
                  </h3>
              </OverviewContent>
          </ProductWrapper>
        </DailyFlex>
        )),
    [data, filterProducts]
  );

  const popularNodes = useMemo(
    () =>
      data.pop.edges
        .filter(filterProducts)
        .map(({ node: product }) => (
        <DailyFlex
          key={product.id}
          id={product.id}
          className="daily_grid"
          key={product.id}
        >
          <ProductWrapper>
              <OverviewImg
                  gutter={product.productimg[0].fluid.aspectRatio}
              >
                <Link
                  to={`/shop/${product.slug}`}
                >
                  <Img
                      fluid={product.productimg[0].fluid}
                      alt={product.title}
                      style={{ backgroundColor: "black" }}
                      durationFadeIn={500}
                      placeholderStyle={
                          {
                              // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                              // "boxShadow": "0 0 100px 200px inset black",
                          }
                      }
                  />
                </Link>
              </OverviewImg>

              <OverviewContent>
                  <h3
                      id="w-node-0e4a4f7ac915-5940d611"
                      className="subheader daily-headers"
                  >
                      {product.title}
                  </h3>
              </OverviewContent>
          </ProductWrapper>
        </DailyFlex>
        )),
    [data, filterProducts]
  );

  return (
    <Layout parent="printshop-page">
      <div className="page-products">
        <Header content_color="black" />
        <IntroSection>
            {/* TODO: REPLACE WITH CMS FIELDS */}
            {/* TODO: ADD VIGNETTE LAYER */}
            <Section>
                <div className="intro__content">
                    <Text ultra style={{color:'var(--black)'}}>
                        Printshop
                    </Text>
                    {/*<Text body style={{color:'var(--black)'}}>
                        Perspectives on our planet and how it's being impacted
                        <br />
                        <br />
                    </Text>*/}
                </div>
            </Section>
        </IntroSection>

        <SearchWrapper>
          <Input
            type="text"
            name="Search: "
            onChange={(e) => setQuery(e.target.value)}
            id="search"
            placeholder="Search"
            value={query}
          />
        </SearchWrapper>

        <Filters>
          <div className="overview-container" label="All Prints">{allProductNodes}</div>
          <div className="overview-container" label="New Releases">{newReleaseNodes}</div>
          <div className="overview-container" label="Popular">{popularNodes}</div>
        </Filters>
      </div>
      <Footer />
    </Layout>
  );
};

export default PrintsPage;

export const query = graphql`
  query PrintsPageQuery {
    datoCmsPrintshopPage {
      featuredShopImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      customBlockImage {
        fluid {
          base64
          aspectRatio
          width
          height
          src
          srcSet
          sizes
        }
      }
      introText
    }
    allDatoCmsProduct(
      filter: { hiddenProduct: { eq: false } }
      sort: { order: ASC, fields: title }
    ) {
      edges {
        node {
          title
          id
          productimg {
            fluid(imgixParams: { h: "600", w: "600", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          caption
          source
          slug
        }
      }
    }

    prints: allDatoCmsProduct(
      filter: { hiddenProduct: { eq: false } }
      sort: { order: ASC, fields: title }
    ) {
      edges {
        node {
          title
          id
          productimg {
            fluid(imgixParams: { h: "600", w: "600", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          caption
          source
          slug
        }
      }
    }
    newreleases: allDatoCmsProduct(
      filter: { hiddenProduct: { eq: false }, tags: { eq: "New Releases" } }
    ) {
      edges {
        node {
          title
          id
          productimg {
            fluid(imgixParams: { h: "600", w: "600", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          caption
          source
          slug
        }
      }
    }
    pop: allDatoCmsProduct(
      filter: { hiddenProduct: { eq: false }, tags: { eq: "Most Popular" } }
    ) {
      edges {
        node {
          title
          id
          productimg {
            fluid(imgixParams: { h: "600", w: "600", fit: "crop" }) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
              sizes
            }
          }
          caption
          source
          slug
        }
      }
    }
  }
`;
